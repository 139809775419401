import 'bootstrap/js/dist/alert';

function Form() {
    $('input,textarea,select').each(function(){
        if ($(this).val().length > 0) {
            $(this).addClass("filled");
        } else {
            $(this).removeClass("filled");
        }
    });

    $('input,textarea,select').on('blur', function(){
        if ($(this).val().length > 0) {
            $(this).addClass("filled");
        } else {
            $(this).removeClass("filled");
        }
    });

    $('input,textarea,select').on('focus', function(){                                    
        if ($(this).parent().hasClass("has-error")){
            $(this).parent().removeClass("has-error");
        }

        if ($(this).hasClass("is-invalid")){
            $(this).removeClass("is-invalid");
        }

        if ($(this).parent().hasClass("is-invalid")){
            $(this).parent().removeClass("is-invalid");
        }
    });

    $('input[type=radio]').click(function(){
      if ($(this).hasClass('is-invalid')) {
        var name = $(this).attr('name');
        $('input[name='+ name +']').removeClass('is-invalid');
      }
    });
  };

  export default Form;

//import ajax from "@fdaciuk/ajax";
//import 'bootstrap/js/dist/alert';
//import ImgLoad from "../util/imgload";
//import ScrollTo from "../util/scrollto";
//import Scroller from "../util/scroller";
//import ElevatorScroller from "../components/elevator-scroller";
//import Swiper from "swiper"; 
import objectFitImages from 'object-fit-images'; // https://github.com/bfred-it/object-fit-images
//import Featherlight from "featherlight";

const isBot = !("onscroll" in window) || /glebot/.test(navigator.userAgent);

// require("modernizr");

export default {
    init() { 

        // object-fit polyfill
        if (document.querySelector('.img-cover')){
            objectFitImages('img.img-cover');
        }

        // object-fit polyfill
        if (document.querySelector('.img-contain')){
            objectFitImages('img.img-contain');
        }

        // new ElevatorScroller();

        // var footer = {
        //     element: document.querySelector(".footer"),
        //     scroll: "",
        //     callback: function() {
        //         this.classList.add("is-visible");
        //     },
        // };

        // if (footer.element) {
        //     footer.scroll = new Scroller(footer.element, {
        //         callback: footer.callback,
        //         trigger: "top",
        //         triggerOffset: 0,
        //         triggerOffsetPercentage: 50,
        //         once: true
        //     });
        // }
        
        if (isBot) {
            document.body.classList.add("bot");
        }
    },
    finalize() {
        //var imgload = new ImgLoad(".banner__image");
        //var downArrow = new ScrollTo(".scroll-down");
    }
};
function callback() {
    //this.classList.add("is-visible");
}

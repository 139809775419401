// require('webpack-jquery-ui/css');
// require('webpack-jquery-ui/datepicker');
require("flatpickr/dist/themes/confetti.css");

//import 'bootstrap/js/dist/collapse';
//import 'bootstrap/js/dist/dropdown';
//import 'bootstrap/js/dist/util';

import Router from "./util/Router";
import PageController from "./util/page-controller";

// Routes
import common from "./routes/common";
import home from "./routes/home";
import register from "./routes/register";

const routes = new Router({
    common,
    home,
    register
});

function ready(func) {
    if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
        func();
    } else {
        document.addEventListener("DOMContentLoaded", func);
    }
}

function loadEvents() {
    if (!document.body.classList.contains("ie") && !document.body.classList.contains("edge")) {
            const pagecontroller = new PageController({
            // classes: {
            //     link: ".ajax",
            //     wrapper: ".wrapper",
            //     transitionElement: ".ajax-transition",
            //     in: "transition-in",
            //     out: "transition-out"
            // },
            events: {
                afterLoad: function () {
                    routes.loadEvents();
                }
            }
        });
    } else {
        routes.loadEvents();
    }    
}

ready(loadEvents);
//import Scroller from "../util/scroller";

import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/util';
import $ from 'jquery';
import flatpickr from "flatpickr";
import Form from "../components/form";

// function callback() {
//     this.classList.add("is-visible");
// }

export default {
    init() {  
      new Form();

      //$('body').scrollspy({ target: '#list-example' });


      //$("#fleaTreatmentDatepicker").flatpickr();

      flatpickr(".datepick", {
        dateFormat: 'd/m/Y',
        maxDate: 'today'
      });

      flatpickr(".datepickrange", {
        dateFormat: 'd/m/Y',
        minDate: 'today',
        mode: 'range'
      });

      flatpickr("#arrival", {
        dateFormat: 'd/m/Y h:i K',
        minDate: 'today',
        enableTime: true
      });

      flatpickr("#collection", {
        dateFormat: 'd/m/Y h:i K',
        minDate: new Date().fp_incr(1),
        enableTime: true
      });

      $('.insurance-group').css({'height': 0, 'opacity': 0});
      $('input[name=insured]').click(function(){
        if (this.value === 'yes') {
          $('.insurance-group').css({'height': '100px', 'opacity': 1});
        } else {
          $('.insurance-group').css({'height': 0, 'opacity': 0});
        }
      });

      // console.log($('#registerForm').serialize());

      // Register Form
      $(document).on('submit', '#registerForm', function(e){ 
          e.preventDefault();

          var form = $('#registerForm');
              // remove messages
              form.parent().find('div.messages').remove();
              // remove error classes
              form.removeClass('was-validated');
              $('.has-error').removeClass('has-error');
              $('.is-invalid').removeClass('is-invalid');

          // hide submit button and show loading animation
          var button = form.find('button[type=submit]');
              button.hide();
              button.siblings('img.ajax-spinner').show();

          $.ajax({
              method: 'POST',
              url: form.attr('action'),
              data: form.serialize(),
              dataType: 'json',
              timeout: 60000, // 60 seconds
              success: function(response){
                  button.siblings('img.ajax-spinner').hide();
                  button.show();
                  if (response.success === true) {
                      form.before('<div class="bg-success messages" role="alert"><p>Thank you for regsitering with I Pet Sit, we\'ll be in touch shortly!</p></div>');
                      //$('#registerForm').reset();
                      $('p.register-text').remove();
                      $('html, body').animate({scrollTop: $('div.messages').offset().top - 30}, 500);
                  } else {
                      var errorMsg = '';
                      
                      if (response.errors && response.errors.length > 0){
                          for (var i = 0; i<response.errors.length; i++){
                              errorMsg += response.errors[i];
                          }
                      } else {
                          errorMsg = "<p>Sorry but an error occurred. Please try again later.</p>";
                      }

                      // display error message
                      form.before('<div class="bg-danger messages" role="alert">'+ errorMsg +'</div>');

                      // Highlight missing required fields
                      if (response.missing){
                          //console.log('missing:');
                          //console.log(response.missing);
                          for (var k in response.missing){
                              if (!$('#'+k).parent().hasClass('has-error')){
                                  $('#'+k).parent().addClass('has-error');
                              }

                              switch(k) {
                                case 'sex':
                                  $('#'+k+'_male').addClass('is-invalid');
                                  $('#'+k+'_female').addClass('is-invalid');
                                  continue;
                                  break;
                                case 'consent_social':
                                case 'neutered_spayed':
                                case 'insured':
                                case 'possessive':
                                case 'happy_around_pets':
                                case 'happy_around_children':
                                case 'treats':
                                case 'leash':
                                case 'play':
                                case 'flea_collar':
                                  $('#'+k+'_yes').addClass('is-invalid');
                                  $('#'+k+'_no').addClass('is-invalid');
                                  continue;
                                  break;
                              }

                              if (!$('#'+k).hasClass('is-invalid')){
                                  $('#'+k).addClass('is-invalid');
                              }
                          }
                      }

                      // Highlight invalid fields
                      if (response.invalid){
                          //console.log('invalid:');
                          //console.log(response.invalid);
                          for (var k in response.invalid){
                              if (!$('#'+k).parent().hasClass('has-error')){
                                  $('#'+k).parent().addClass('has-error');
                              }

                              if (!$('#'+k).hasClass('is-invalid')){
                                  $('#'+k).addClass('is-invalid');
                              }
                          }
                      }
                  }
                  // scroll to top of message
                  $('html, body').animate({scrollTop: $('div.messages').offset().top - 30}, 500);
              },
              error: function(xhr, status, errorThrown){
                  button.siblings('img.ajax-spinner').hide();
                  button.show();
                  //button.disabled = false;
                  switch(status){
                      case 'abort': // user abort is not an error
                          break;
                      case 'timeout':
                          form.prepend('<div class="bg-danger messages" role="alert"><p>The request timed out, please try again.</p></div>');
                          break;
                      default:
                          var errorMsg = 'An error occurred.';
                          if (errorThrown != '') errorMsg += '<br />' + errorThrown;
                          form.prepend('<div class="bg-danger messages" role="alert"><p>'+ errorMsg +'</p></div>');
                  }
                  $('html, body').animate({scrollTop: $('div.messages').offset().top - 30}, 500);
              }
          });
      });
    }
};
//import Scroller from "../util/scroller";

import 'bootstrap/js/dist/alert';

// function callback() {
//     this.classList.add("is-visible");
// }

export default {
    init() {

   // Tabs 
    $('#services-tabs .tab-content').each(function(index, element){
        if (index != 0){
            $(element).hide();
        }
    });

    $('#services-list a').on('click', function(ev){
        ev.preventDefault();
        $(this).parent().siblings().removeClass('current');
        if (! $(this).parent().hasClass('current')){
            $(this).parent().addClass('current');
        }

        var tab = $(this).data('tab');

        $('#services-tabs .tab-content').each(function(index, element){
            if ($(element).attr('id') != tab){
                $(element).hide();
            } else {
                $(element).show();
            }
        });
    });

    // Contact Form
    $(document).on('submit', '#contactForm', function(e){ 
        e.preventDefault();

        var form = $(this);
            // remove messages
            form.parent().find('div.messages').remove();
            // remove error classes
            $('div.form-group').removeClass('has-error');
        // hide submit button and show loading animation
        var button = form.find('button[type=submit]');
            button.hide();
            button.siblings('img.ajax-spinner').show();
            button.siblings('p.call-text').hide();
        $.ajax({
            method: 'POST',
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            timeout: 60000, // 60 seconds
            success: function(response){
                button.siblings('img.ajax-spinner').hide();
                button.siblings('p.call-text').show();
                button.show();
                if (response.success === true) {
                    form.before('<div class="bg-success messages" role="alert"><p>Thank you for contacting I Pet Sit, we\'ll be in touch shortly.</p></div>');
                    form.reset();
                    $('p.contact-text').remove();
                } else {
                    var errorMsg = '';
                    if (response.errors && response.errors.length > 0){
                        for (var i = 0; i<response.errors.length; i++){
                            errorMsg += response.errors[i];
                        }
                    } else {
                        errorMsg = "<p>Sorry but an error occurred. Please try again later.</p>";
                    }
                    // display error message
                    form.before('<div class="bg-danger messages" role="alert">'+ errorMsg +'</div>');

                    // Highlight missing required fields
                    if (response.missing){
                        for (var k in response.missing){
                            if (!$('#'+k).parent().hasClass('has-error')){
                                $('#'+k).parent().addClass('has-error');
                            }
                            if (!$('#'+k).hasClass('is-invalid')){
                                $('#'+k).addClass('is-invalid');
                            }
                        }
                    }

                    // Highlight invalid fields
                    if (response.invalid){
                        for (var k in response.invalid){
                            if (!$('#'+k).parent().hasClass('has-error')){
                                $('#'+k).parent().addClass('has-error');
                            }
                            if (!$('#'+k).hasClass('is-invalid')){
                                $('#'+k).addClass('is-invalid');
                            }
                        }
                    }
                }
                // scroll to top of message
                $('html, body').animate({scrollTop: $('div.messages').offset().top - 30}, 500);
            },
            error: function(xhr, status, errorThrown){
                button.siblings('img.ajax-spinner').hide();
                button.siblings('p.call-text').hide();
                button.show();
                //button.disabled = false;
                switch(status){
                    case 'abort': // user abort is not an error
                        break;
                    case 'timeout':
                        form.prepend('<div class="bg-danger messages" role="alert"><p>The request timed out, please try again.</p></div>');
                        break;
                    default:
                        var errorMsg = 'An error occurred.';
                        if (errorThrown != '') errorMsg += '<br />' + errorThrown;
                        form.prepend('<div class="bg-danger messages" role="alert"><p>'+ errorMsg +'</p></div>');
                }
                $('html, body').animate({scrollTop: $('div.messages').offset().top - 30}, 500);
            }
        });
    });
    },
    finalize() {

    }
};